import allLocales from "@fullcalendar/core/locales-all";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import {vue} from '@/main'
import interactionPlugin from "@fullcalendar/interaction";

export function getTimeLineDefaults(resources) {
    return {
        resourceAreaWidth: '20%',
        schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
        locales: allLocales,
        locale: vue.$t('languageFormat.code'),//this.currentLang, // the initial locale
        plugins: [resourceTimelinePlugin, interactionPlugin],
        initialView: 'resourceTimelineMonth',
        slotDuration: {hours:24}, //{days: 1},
        //https://fullcalendar.io/docs/slotLabelFormat
        slotLabelFormat: [
            //https://fullcalendar.io/docs/date-formatting
            { day: 'numeric', month: 'numeric',  weekday: 'short', omitCommas: true }, // top level of text
        ],
        dayHeaderFormat: { weekday: 'short', month: 'numeric', day: 'numeric', omitCommas: true },
        resourceGroupField: 'groupId',
        displayEventTime: false,
        headerToolbar: {
            left: 'today prev,next',
            center: 'title',
            right: 'resourceTimelineWeek,resourceTimeline15Day,resourceTimelineMonth,resourceTimeline3Month'
        },
        resources: resources || [],
        views:{
            resourceTimeline15Day: {
                type: 'resourceTimeline',
                duration: { days: 15 },
                buttonText: vue.$t('calendarPicker.XDays',{days:15}),
            },
            resourceTimeline3Month: {
                type: 'resourceTimeline',
                duration: { months: 3 },
                buttonText: vue.$t('calendarPicker.XMonths',{months:3}),
            },
        }
    }
}

//responsive views #3187
//https://github.com/fullcalendar/fullcalendar/issues/3187
