<template>
  <li
      class="timeline-item"
      :class="[`timeline-variant-${variant }`, fillBorder ? `timeline-item-fill-border-${variant }` : null
      ]"
  >
    <!-- Timeline Point or Icon -->
    <div v-if="icon" class="timeline-item-icon d-flex align-items-center justify-content-center rounded-circle">
      <feather-icon :icon="icon"/>
    </div>
    <div v-else class="timeline-item-point"/>

    <!-- Content Slot -->
    <slot>
      <!-- Timeline Time -->
      <div>
        <slot name="time">
          <span style="font-weight: normal">{{msg('Modified at')}}: </span> <span class="timeline-item-time text-nowrap"
                                                                  style="font-weight: bold"
                                                                  v-text="time"/>
        </slot>
      </div>

      <!-- Source Information -->
      <div>
        <slot name="source">
          <span style="font-weight: normal">{{msg('User')}}: </span> <span class="timeline-item-source mb-0 "
                                                                  style="font-weight: bolder">{{ source }}</span>
        </slot>
      </div>

      <!-- Channel Information with Copy Icon -->
      <div>
        <slot name="channel">
          <span style="font-weight:normal">{{msg('Dates')}}: </span> <span class="timeline-item-source mb-0 "
                                                                 style="font-weight: bolder;color: #ab8d39">{{ start }} - {{end}}</span>
        </slot>
      </div>

      <div>
        <slot name="channel">
          <span style="font-weight: normal">{{msg('Price')}}: </span> <span class="timeline-item-source mb-0 "
                                                                 style="font-weight: bolder;color: #00c853">{{ prices }}€ </span>
        </slot>
      </div>


    </slot>
  </li>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  props: {
    variant: {
      type: String,
      default: 'danger',
    },
    id: String, // our preoprty db id
    historyId: String, //
    start: {
      type: String,
      default: null,
    },
     end: {
      type: String,
      default: null,
    },

    prices: {
      type: String,
      default: null,
    },

    title: {
      type: String,
      default: null,
    }, data: {
      type: String,
      default: null,
    },
    source: {
      type: String,
      default: null,
    },
    time: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    fillBorder: {
      type: Boolean,
      default: false,
    },

  },
  computed: {},
  methods: {

    markAsExported() {
      this.$emit('submitted', {realEstateId: this.id, historyId: this.historyId});

    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(() => {
        this.$bvToast.toast('Copied to clipboard!', {
          title: 'Success',
          variant: 'success',
          solid: true,
          autoHideDelay: 2000,
        });
      });
    },
  },
}
</script>

<style lang="scss" scoped>


@import '~@core/scss/base/bootstrap-extended/include';
// Bootstrap includes
@import '~@core/scss/base/components/include';
// Components includes

// Color palettes
@import '~@core/scss/base/core/colors/palette-variables.scss';

$timeline-border-color: $border-color;

/* Generate:
*  Apply background color to dot
*/
@each $color_name, $color in $colors {
  @each $color_type, $color_value in $color {
    @if $color_type== 'base' {
      .timeline-variant-#{$color_name} {
        &.timeline-item-fill-border-#{$color_name} {
          border-color: $color_value !important;

          &:last-of-type {
            &:after {
              background: linear-gradient($color_value, $white);
            }
          }
        }

        .timeline-item-point {
          background-color: $color_value;

          &:before {
            background-color: rgba($color_value, 0.12);
          }
        }

        .timeline-item-icon {
          color: $color_value;
          border: 1px solid $color_value;
        }
      }
    }
  }
}

.copy-icon {
  cursor: pointer;
  color: #dabc38;
}

.copy-icon:hover {
  color: #0056b3;
}

.timeline-item {
  padding-left: 2.5rem;
  position: relative;

  &:not(:last-of-type) {
    padding-bottom: 2rem;
    border-left: 1px solid $timeline-border-color;
  }

  // This gives shade to last timeline-item but isn't that revolutionary
  &:last-of-type {
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 1px;
      height: 100%;
      background: linear-gradient($timeline-border-color, $white);
    }
  }

  .timeline-item-point {
    position: absolute;
    left: -6px;
    width: 12px;
    height: 12px;
    top: 0;
    border-radius: 50%;
    z-index: 1;

    &:before {
      content: '';
      z-index: 1;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      position: absolute;
      top: -4px;
      left: -4px;
      bottom: 0;
      right: 0;
    }
  }

  .timeline-item-icon {
    position: absolute;
    left: -12px;
    top: 0;
    width: 24px;
    height: 24px;
    background-color: $white;
    z-index: 1;
  }
}

// *===============================================---*
// *--------- Dark Layout ---------------------------------------*
// *===============================================---*

.dark-layout {
  .timeline-item {
    &:last-of-type {
      &:after {
        background: linear-gradient($theme-dark-border-color, $theme-dark-card-bg);
      }
    }

    &:not(:last-of-type) {
      border-left-color: $theme-dark-border-color;
    }

    .timeline-item-icon {
      background-color: $theme-dark-card-bg;
    }
  }
}

</style>
