import {parseDate} from "echarts/lib/util/number";

export function middleDate($moment, a, b) {
    let diff = Math.abs($moment(a).diff(b))
    let middle = (diff / 2) + Math.min($moment(a).valueOf(), $moment(b).valueOf())
    return $moment(middle)
}

export function convertRange($moment, start, end) {
    let rangeMiddleDate = middleDate($moment, start, end)
    let month = $moment(rangeMiddleDate).month()
    let startStr = $moment(rangeMiddleDate).startOf('month')
        .format('YYYY-MM-DD HH:mm')
    let endStr = $moment(rangeMiddleDate).endOf('month')
        .format('YYYY-MM-DD HH:mm')
    return {start: startStr, end: endStr, month}
}


export function getEventColor(event) {
    if (event == null || event.platform == null)
        return 'rgba(199,166,128,0.44)';

    let platform = event.platform;
    if (platform === 'Booking')
        return "rgba(49,121,231,0.27)"

    if (platform === 'Expedia')
        return "rgba(239,221,9,0.5)"

    if (platform === 'Airbnb')
        return "rgba(255,82,88,0.57)"

    if (platform === 'Vrbo')
        return "#dfe6e9"

    if (platform === 'Agoda')
        return "#2ecc71"

    if (platform === 'Treasure Home')
        return "#c7a680"

    if (platform === 'Pricelabs')
        return "rgb(255,255,255)";

    if (platform === 'BLOCKED')
        return "rgb(255,0,0)";

    if (platform === 'Unknown')
        return "rgba(236,228,207,0.73)";

    return "rgba(199,166,128,0.44)";
}

/***
 * Find days that are not used in the events
 * @param events : Array of events
 * @param groupKey : String to group the events
 * @param $moment : Moment library
 * @param start : String date
 * @param end : String date
 * @param notUsedDayCallback : function(day, groupKey:string)
 */
export function findEventsGroupedNonUsedDays(events, groupKey, $moment, start, end, notUsedDayCallback) {
    let eventsByGroup = events.reduce((acc, event) => {
        let key = event[groupKey]
        if (acc[key] == null) {
            acc[key] = []
        }
        acc[key].push(event)
        return acc
    }, {})
    let usage = {}
    Object.keys(eventsByGroup).forEach(groupKey => {
        let events = eventsByGroup[groupKey]
        // console.log('Finding non used days for group:', groupKey)
        usage[groupKey] = findNonUsedDays(events, $moment, start, end, (day) => notUsedDayCallback(day, groupKey));
        if(groupKey === "65c9efe7a84807221a4ae264"){
            console.log('65c9efe7a84807221a4ae264 usage:', usage[groupKey])
        }
    })
    return usage
}

/***
 * Find days that are not used in the events
 * @param events : Array of events
 * @param notUsedDayCallback : function(day:moment)
 *///65c9efe7a84807221a4ae264
export function findNonUsedDays(events, $moment, start, end, notUsedDayCallback) {
    let totalNotUsed = 0
    let totalDays = 0;
    let rUsedDays = [];
    let startDay = $moment(start).startOf('day')
    let endDay = $moment(end).endOf('day')
    let days = $moment.range(startDay, endDay).by('days');
    let usedDays = events.flatMap(e => getEventUsedDates($moment, e))
        .filter(e => e.dayMoment.isBefore($moment(e.day +  " 16:00"))); //Remove checkin days
    // console.log('Used days:', usedDays)
    usedDays = [...new Set(usedDays.map(e => e.day))]
    // console.log('Used days after:', usedDays)
    for (let day of days) {
        if (!usedDays.includes(day.format('YYYY-MM-DD'))) {
            notUsedDayCallback(day)
            totalNotUsed++
        }else {
            rUsedDays.push(day.format('YYYY-MM-DD'))
        }
        totalDays++;
    }
    return {totalNotUsed, usedDays: totalDays-totalNotUsed, totalDays, usage: parseFloat(((1 - (totalNotUsed / totalDays))*100).toFixed(2)), rUsedDays, guests: events.length}
}

/***
 * Get the days used by an event
 * The method separate the checkin day from the rest of the days and returns an array of objects with the event, the title, the day, the hour, the full day and the moment
**/

function getEventUsedDates($moment, e){
    let checkin = $moment(e['start']); // Checkin day (starting at moon)
    let firstDay = $moment(checkin).add(1, 'day').startOf('day'); // Day after checkin
    let stayDays = $moment.range(firstDay, e['end']).by('day'); // Days after checkin
    let eventDays = [checkin, ...Array.from(stayDays)]
    return eventDays
        .map(d => ({e, title: e.title, day: d.format('YYYY-MM-DD'), hour: d.format('HH'), fullDay: d.format('YYYY-MM-DD HH:mm'), dayMoment:d}))
}
